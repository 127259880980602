import Application from '../Application';
import BakedModel from '../Utils/BakedModel';
export default class Panda {
    constructor() {
        this.application = new Application();
        this.scene = this.application.scene;
        this.resources = this.application.resources;
        this.bakeModel();
        this.setModel();
    }
    bakeModel() {
        this.bakedModel = new BakedModel(this.resources.items.gltfModel.pandaModel, this.resources.items.texture.pandaTexture, 900);
    }
    setModel() {
        this.scene.add(this.bakedModel.getModel());
    }
}
